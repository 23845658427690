<template>
  <div class="cont-warp">
    <div class="crumbs-cont">
      <div class="crumbs">所在位置：
        <router-link to="/"> 首页</router-link>
        /
        <router-link to="/securityTrain"> 证书培训</router-link>
        / 一考双证
      </div>
    </div>
    <div class="cont-img">
      <router-link to="/cdsePage">
        <img src="../../assets/images/securityTrain/pc/advantage/pic01.png"/>
      </router-link>
    </div>
    <div class="item-img">
      <router-link to="/cdseAPage">
        <div class="pic-img">
          <img src="../../assets/images/securityTrain/pc/advantage/pic02.png"/>
        </div>
      </router-link>
    </div>
    <div class="cont-img">
      <router-link to="/dsmmPage">
        <img src="../../assets/images/securityTrain/pc/advantage/pic03.png"/>
      </router-link>
    </div>
     <erweima></erweima>
  </div>
</template>

<script>
import Erweima from './pub/erweima'
export default {
  name: 'Home',
  components: {Erweima},
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('/h5Advantage');
    } else {
      this.$router.replace('/advantage');
    }
  },

}
</script>
<style lang="less" scoped>
.crumbs-cont {
  text-align: left;
  width: 100%;
  line-height: 34px;
  height: 34px;
  background: #F3F6F8;


  .crumbs {
    width: 1200px;
    margin: 0 auto;

    a {
      font-size: 14px;
      color: #333333;

      &:hover {
        color: #0079F4;
      }
    }

    font-size: 14px;
    color: #333333;
  }
}

.cont-img {
  width: 1200px;
  margin: 0 auto;

  img {
    max-width: 100%;
    margin-top: 50px;
  }

  .erweima-img {
    max-width: 730px;
    margin: 0 auto 50px;
  }
}

.item-img {
  width: 100%;
  height: 650px;
  background: #0079F4;

  .pic-img {
    width: 1200px;
    margin: 0 auto;

    img {
      max-width: 100%;
      margin-top: 50px;
    }
  }

}
</style>
